@tailwind base;
@tailwind components;
@tailwind utilities;

/* @layer base {
    body {
        @apply font-secondary text-secondary bg-gradient-to-b from-[#F6FAFD] to-[#FFFFFF] bg-no-repeat;
    }
    
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        @apply font-primary text-primary;
    }
    
    a {
        @apply font-semibold transition text-secondary hover:text-accent-primary_hover;
    }
    
    .btn {
        @apply text-lg h-[60px] px-[30px] rounded-full transition-all duration-300;
      }
      
      .card {
        @apply p-4 bg-white rounded-lg shadow-lg;
      }
      
    
    .btn-primary {
        @apply bg-accent-primary hover:bg-accent-primary_hover;
    }

    .btn-primary:hover {
        @apply transform bg-accent-primary_hover hover:scale-105;
      }
      
      .card:hover {
        @apply shadow-xl;
      }
      
    
    .btn-secondary {
        @apply bg-accent-secondary hover:bg-accent-secondary_hover;
    }
    
    .btn-quaternary {
        @apply text-base font-bold bg-white text-primary drop-shadow-primary hover:bg-accent-primary_hover;
    }
    
    .h1 {
        @apply text-4xl font-bold leading-tight xl:text-6xl;
    }
    
    .h2 {
        @apply text-4xl font-bold leading-tight;
    }
    
    .h3 {
        @apply text-2xl font-semibold;
    }
    
    .lead {
        @apply text-lg font-medium leading-relaxed text-secondary;
    }
} */
